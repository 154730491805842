@import url('https://fonts.googleapis.com/css?family=Dancing+Script');
@import url('https://fonts.googleapis.com/css?family=Preahvihear');
.underlinedText{
  cursor: pointer;
}

.questionMark{
  font-size: 26px;
  cursor: pointer;
}

.poolBox{
	padding: 10px;
}

.page{
  display: flex;
  flex-direction: column;
  justify-content: center;
}

form{
  padding: 0px;
}

.modalContainer{
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 5px solid rgb(173, 88, 39);
  padding: 25px;
  width: auto;
  outline: none;
  background: white;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Syne' !important;
}

.modalContainer option{
  color: black;
  padding: 25px !important;
  font-size: 16x;
  height: 50px !important;
  font-family: 'Syne' !important;
}

.modalContainer__dictionary{
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
  font-family: 'Syne' !important;
}

.modalContainer__tiles{
  display: flex;
  flex-direction: column;
  font-family: 'Syne' !important;
}

.randomizeBtn{
  cursor: pointer;
  font-size: 30px !important;
}

.styleSelection{
  padding: 7px;
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 16x;
  cursor: pointer;
  height: auto; 
  background: linear-gradient(45deg, transparent 5%, #14000b 5%);
  border: 0;
  color: #fff;
  box-shadow: 6px 0px 0px #00E6F6;
  outline: transparent;
  position: relative;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  font-family: 'Syne' !important;
}
.settingsBtn{
  font-size: 30px !important;
  cursor: pointer;
}
.keyBtn{
  font-size: 30px !important;
  line-height: 30px !important;
  padding: 2px 10px;
  height: 30px;
  font-family: 'Dancing Script' !important;
  cursor: pointer;
  transform: translate(--slice-0);
  user-select: none;
}
.keyBtnSmall{
  font-size: 18px !important;
  cursor: pointer;
  transform: translate(--slice-0);
}
.recentGames{
  font-size: 16px !important;
}
.recentGames td{
  padding: 8px;
}
.viewRecentGames{
  display: flex;
  align-items: center;
}
.viewRecentGames svg{
  margin-left: 2px;
}
.customInputNum{
  padding: 10px;
  width: auto;
  height: auto; 
  background: linear-gradient(45deg, transparent 5%, #14000b 5%);
  border: 0;
  color: #fff;
  box-shadow: 6px 0px 0px #00E6F6;
  outline: transparent;
  position: relative;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.title{
  display: flex;
  justify-content: center;
  font-size: 30px;
  margin-top: 25px;
  font-family: 'Preahvihear' !important;
}

.mainPanel{
  display: flex;
  flex-direction: row;
}

.playerPanel{
  width: 380px;
  height: auto;
  font-size: 20px;
  background-image: url("https://www.transparenttextures.com/patterns/bright-squares.png");
  border: 0;
  color: #fff;
  position: relative;
  line-height: 50px;
}

.playerToggle{
  display: flex;
  justify-content: space-evenly;
  padding: 10px 0;
  align-items: center;
}

.unlockText{
  font-size: 10px;
  font-style: italic;
  font-family: 'Syne' !important;
  padding: 10px;
  justify-content: flex-end;
}

/*.playerPanel:after {
  --slice-0: inset(50% 50% 50% 50%);
  --slice-1: inset(80% -6px 0 0);
  --slice-2: inset(50% -6px 30% 0);
  --slice-3: inset(10% -6px 85% 0);
  --slice-4: inset(40% -6px 43% 0);
  --slice-5: inset(80% -6px 5% 0);
  
  content: 'ALTERNATE TEXT';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(45deg, transparent 3%, #00E6F6 3%, #00E6F6 5%, #756f66 5%);
  text-shadow: -3px -3px 0px #F8F005, 3px 3px 0px #00E6F6;
  clip-path: var(--slice-0);
}

.playerPanel:after {
  --slice-0: inset(50% 50% 50% 50%);
  --slice-1: inset(80% -6px 0 0);
  --slice-2: inset(50% -6px 30% 0);
  --slice-3: inset(10% -6px 85% 0);
  --slice-4: inset(40% -6px 43% 0);
  --slice-5: inset(80% -6px 5% 0);
  
  content: 'ALTERNATE TEXT';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(45deg, transparent 3%, #00E6F6 3%, #00E6F6 5%, #756f66 5%);
  text-shadow: -3px -3px 0px #F8F005, 3px 3px 0px #00E6F6;
  clip-path: var(--slice-0);
}

.playerPanel:after {
  animation: 1s glitch;
  animation-timing-function: steps(2, end);
}*/

@keyframes glitch {
  0% {
    clip-path: var(--slice-1);
    transform: translate(-20px, -10px);
  }
  10% {
    clip-path: var(--slice-3);
    transform: translate(10px, 10px);
  }
  20% {
    clip-path: var(--slice-1);
    transform: translate(-10px, 10px);
  }
  30% {
    clip-path: var(--slice-3);
    transform: translate(0px, 5px);
  }
  40% {
    clip-path: var(--slice-2);
    transform: translate(-5px, 0px);
  }
  50% {
    clip-path: var(--slice-3);
    transform: translate(5px, 0px);
  }
  60% {
    clip-path: var(--slice-4);
    transform: translate(5px, 10px);
  }
  70% {
    clip-path: var(--slice-2);
    transform: translate(-10px, 10px);
  }
  80% {
    clip-path: var(--slice-5);
    transform: translate(20px, -10px);
  }
  90% {
    clip-path: var(--slice-1);
    transform: translate(-10px, 0px);
  }
  100% {
    clip-path: var(--slice-1);
    transform: translate(0);
  }
}

.rightPanel{
	padding: 24px;
	display: flex;
	flex-direction: column;
  justify-content: flex-start;
}

.topPlayerPanel{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 380px;
  padding-bottom: 24px;
}

.groupedBox{
  cursor: pointer;
  color: #fff;
  display: flex;
}

.Arrows{
  cursor: pointer;
  font-size: 30px !important;
}

.mainBox{
  display: flex;
  justify-content: center;
}

.Rack{
	display: flex;
  justify-content: center;
}

.commentaryContainer{
  background: #4D3D2B;
  background-image: url("https://www.transparenttextures.com/patterns/light-mesh.png");
}

.commentaryBox{
  padding: 10px;
  font-family: 'Syne';
  overflow-wrap: break-word;
  text-align: justify;
  font-size: 13px;
  line-height: 18px;
  font-family: 'Syne';
}


@media screen and (max-width: 992px) {
  .rightPanel{
    padding: 24px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }
  .mainPanel{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
  .topPlayerPanel{
    padding-bottom: 0px;
    padding-right: 24px;
  }
  .page{
    margin-top: 100px;
  }
  .playerPanel{
    width: 45vw;
  }
}

@media screen and (max-width: 676px) {
  .rightPanel{
    padding: 0px 0px 24px 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
  .playerToggle {
    padding: auto;
    height: 15px;
  }
  .playerToggle svg{
    font-size: 20px !important
  }
  .questionMark{
    display: none;
  }
  .topPlayerPanel{
    padding-bottom: 12px;
    padding-right: 0px;
    height: 225px;
  }
  .title{
    margin: auto;
    font-size: 15px;
  }
  .page{
    margin-top: 80px;
  }
  body{
    font-size: 10px;
  }
  .playerPanel{
    width: 90vw;
    font-size: 13px;
    line-height: 27px;
  }
  .mainBox{
    padding: 16px !important;
  }
  .keyBtn{
    font-size: 20px !important;
    line-height: 20px !important;
    height: 20px !important;
  }
  .commentaryBox{
    padding: 10px;
    font-family: 'Syne';
    overflow-wrap: break-word;
    text-align: justify;
    font-size: 9px;
    line-height: 14px;
    font-family: 'Syne';
  }
}

@media screen and (max-width: 1200px) {

}

@media screen and (max-height: 992px) and (min-width: 1200px){
  .Board {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 600px;
    height: 600px;
  }
  .playerPanel{
    font-size: 16px;
    line-height: 40px;
  }
  .topPlayerPanel{
    height: 338px;
  }
}