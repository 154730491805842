@import url('https://fonts.googleapis.com/css?family=Josefin+Sans');
.Rack{
    display: flex;
    flex-direction: row;
    font-size: 25px;
    height: 45px;
}
.Rack_protiles{
    display: flex;
    flex-direction: row;
    font-size: 25px;
    height: 100%;
    width: 100%;
    border-radius: 5px;
}
.Tile{
    width: 42px;
    height: 42px;
    background-color: teal;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2.5px;
    border-radius: 6px;
    font-size: 30px;
}
.Protile{
    width: 42px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2.5px;
    border-radius: 4px;
    font-size: 30px;
    filter: brightness(0.85);
}

@media screen and (max-width: 676px) {
    .Rack{
        display: flex;
        flex-direction: row;
        font-size: 25px;
        height: 35px;
    }
    .Tile{
        width: 30px;
        height: 30px;
        font-size: 20px;
    }
    .Protile{
        width: 30px;
        height: 30px;
        font-size: 20px;
    }
}