
*{
	border-spacing: 0px !important;
  user-select: none;
}


/* styles.module.css */

.row {
  display: flex;
  justify-content: center;
}

.spacing {
  margin-bottom: 20px; /* Adjust as needed */
}

.letterBox {
  font-size: 16px;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.circle {
  border: 2px solid blue; /* Example border style */
  border-radius: 50%; /* Make it circular */
}

/* CSS */
.Header{
  position: relative;
  background: rgb(12, 12, 59);
  padding: 10px;
  margin-bottom: 10px;
  font-size: 12px;
}

.sideNumbering{
  font-size: 8px;
  width: 10px;
  height: 10px;
  background:rgb(185, 174, 166);
}

.tableContainer {
  border-radius: 20px;
}

.tableContainer table{
	border-spacing: 0px !important;
	border: 1.5px solid darkgray;
	background-color: lightgray;
  border-radius: 10px;
}

.NWcell{
  border-radius: 8px 0px 0px 0px;
}
.NEcell{
  border-radius: 0px 8px 0px 0px;
}
.SWcell{
  border-radius: 0px 0px 0px 8px;
}
.SEcell{
  border-radius: 0px 0px 8px 0px;
}

.Footer{
  position: relative;
  background: rgb(108, 4, 4);
  padding: 10px;
  margin-top: 10px;
  font-size: 12px;
}

.innerBox{
  display: flex;
}

.Left {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.Right {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 0;
}

td {
  padding: 0px;
}

.modalContainer{
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 5px solid rgb(173, 88, 39);
  padding: 25px;
  width: auto;
  outline: none;
  background: white;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Syne' !important;
}

.slipBox {
  background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.7) 50%, rgba(255, 255, 255, 0.4) 100%);
  width: auto;
  color: black;
  position: absolute;
  margin-left: 10px;
  z-index: 0;
  padding: 10px;
  font-size: 12px;
  cursor: pointer;
}


.Board__STANDARD,
.Board__STANDARD:after {
  background: linear-gradient(to right bottom,rgba(255, 255, 255, 0.65),rgba(255, 255, 255, 0));
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 680px;
  height: 680px;
}

.Board:after {
  --slice-0: inset(50% 50% 50% 50%);
  --slice-1: inset(80% -6px 0 0);
  --slice-2: inset(50% -6px 30% 0);
  --slice-3: inset(10% -6px 85% 0);
  --slice-4: inset(40% -6px 43% 0);
  --slice-5: inset(80% -6px 5% 0);
  
  content: 'ALTERNATE TEXT';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(45deg, transparent 3%, #00E6F6 3%, #00E6F6 5%, #FF013C 5%);
  text-shadow: -3px -3px 0px #F8F005, 3px 3px 0px #00E6F6;
  clip-path: var(--slice-0);
}

.Board:active {
  animation: 1s super-cool-glitch;
  animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

@keyframes super-cool-glitch {
  0%, 100% {
    transform: translate(0, 0) rotate(0deg) scale(1);
  }
  10% {
    transform: translate(-5px, -5px) rotate(-5deg) scale(1.1);
  }
  20% {
    transform: translate(5px, 5px) rotate(3deg) scale(1.2);
  }
  30% {
    transform: translate(0px, 0px) rotate(-2deg) scale(0.9);
  }
  40% {
    transform: translate(-3px, -3px) rotate(2deg) scale(1.15);
  }
  50% {
    transform: translate(2px, 2px) rotate(-2deg) scale(1.05);
  }
  60% {
    transform: translate(0px, 0px) rotate(3deg) scale(1.25);
  }
  70% {
    transform: translate(-5px, -5px) rotate(-5deg) scale(1.1);
  }
  80% {
    transform: translate(5px, 5px) rotate(4deg) scale(1.3);
  }
  90% {
    transform: translate(0px, 0px) rotate(-3deg) scale(1.15);
  }
}

@media screen and (max-width: 992px) {
  .BoardContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: none;
    background: none;
    width: 100%;
    height: 100%;
    box-shadow: none;
  }

  .Board__STANDARD,
  .Board__STANDARD:after {
    width: 100%;
    height: 100%;
  }

}

@media screen and (min-width: 992px) and (max-width: 1200px){
  .BoardContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: none;
    background: none;
    width: auto;
    height: 100%;
  }

  .Board__STANDARD,
  .Board__STANDARD:after {
    width: auto;
    height: 100%;
  }

}

@media screen and (max-height: 992px) and (min-width: 1200px){
  .BoardContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 570px;
    height: 570px;
  }
}

@media screen and (max-width: 676px){
  .sideNumbering {
    font-size: 5px;
    width: 6px;
    height: 6px;
  }
  .Header, .Footer, .slipBox{
    font-size: 8px;
  }
}