@import url('https://fonts.googleapis.com/css?family=Preahvihear');

.container {
    display: flex;
    flex-direction: column;
    margin: 10px;
}

.box {
    margin-bottom: 10px;
}

.filterBox {
    border: 1px solid #ccc;
    padding: 10px;
    color: #fff !important;
}

.whiteText{
    color: white;
}

.tableBox {
    border: 1px solid #ccc;
    padding: 10px;
    overflow-x: auto; /* Enable horizontal scrolling if necessary */
    max-width: 100vw; /* Allow the table to grow up to the full screen width */
}

.autocompleteLabel {
    color: white !important;
}

.autocompleteInput {
    color: white !important;
    border: 1px solid white;
}

.flexCell{
    display: flex;
}

.title{
    display: flex;
    justify-content: center;
    font-size: 30px;
    margin: 75px 0px 40px 0px;
    font-family: 'Preahvihear' !important;
}
  