.Cell__STANDARD{
    width: 30px;
    height: 30px;
    display: flex;  
    font-size: 25px;
    justify-content: center;
    align-items: flex-end;
    z-index: 1;
    -webkit-filter: grayscale(100%);
    -webkit-filter: contrast(0%);
    -webkit-filter: brightness(100%);
}
.decalContainer{
    position: relative;
    height: 30px;
    width: 30px;
}

.powderblue{
    background: linear-gradient(-45deg, transparent 50%, powderblue 0), linear-gradient(45deg, transparent 50%, powderblue 0), linear-gradient(-135deg, transparent 50%, powderblue 0), linear-gradient(135deg, transparent 50%, powderblue 0);
}
.mediumslateblue{
    background: linear-gradient(-45deg, transparent 50%, mediumslateblue 0), linear-gradient(45deg, transparent 50%, mediumslateblue 0), linear-gradient(-135deg, transparent 50%, mediumslateblue 0), linear-gradient(135deg, transparent 50%, mediumslateblue 0);
}
.lightpink{
    background: linear-gradient(-45deg, transparent 50%, lightpink 0), linear-gradient(45deg, transparent 50%, lightpink 0), linear-gradient(-135deg, transparent 50%, lightpink 0), linear-gradient(135deg, transparent 50%, lightpink 0);
}
.BE3939{
    background: linear-gradient(-45deg, transparent 50%, #BE3939 0), linear-gradient(45deg, transparent 50%, #BE3939 0), linear-gradient(-135deg, transparent 50%, #BE3939 0), linear-gradient(135deg, transparent 50%, #BE3939 0);
}

.cellPositioning{
    position: relative;
    z-index: 999;
}
@media screen and (max-width: 676px) {
    .Cell__STANDARD{
        width: 15px;
        height: 15px;
        display: flex;  
        font-size: 13px;
        justify-content: center;
        align-items: flex-end;
    }
    .decalContainer{
        position: relative;
        height: 15px;
        width: 15px;
    }  
}

@media screen and (max-height: 992px)  and (min-width: 1200px){
    .Cell__STANDARD{
        width: 25px;
        height: 25px;
        display: flex;  
        font-size: 22px;
        justify-content: center;
        align-items: flex-end;
    }
    .decalContainer{
        position: relative;
        height: 25px;
        width: 25px;
    }
}