@media screen and (max-width: 992px) {
    .myAppBar {
        display: flex;
    }
    .myDrawer{
        display: none !important;
    }
}

@media screen and (min-width: 993px) {
    .myAppBar {
        display: none !important;
    }
    .myDrawer{
        display: flex;
    }
}

.homeLogo{
    color: white;
    font-size: 50px !important;
}

.listItem{
    padding: 5px !important;
    display: flex;
}

.listItemText{
    color: white;
    margin-left: 25px;
}

.link{
	color: blue !important;
    text-decoration: none !important;
}
.link:hover{
	color: black !important;
    text-decoration: none !important;
}
.cfLogoContainer{
    padding: 20px !important;
    display: flex !important;
    justify-content: center !important;
}
.btnContainer{
    margin-left: 5% !important;
}
.customDrawer{
    position: relative !important;
}