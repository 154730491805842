@import url('https://fonts.googleapis.com/css?family=Syne');
.poolTbl{
    overflow-wrap: break-word;
    text-align: justify;
    font-size: 14px;
    line-height: 28px;
    font-family: 'Syne';
}

.charGroup {
    margin: 0 0.8em 0 0;
    font-family: 'Syne';
}
.red{
    color: orangered;
    font-family: 'Syne';
    overflow-wrap: break-word;
    text-align: justify;
    font-size: 14px;
    line-height: 28px;
    font-family: 'Syne';
}

@media screen and (max-width: 676px) {
    .poolTbl{
        font-size: 10px;
        line-height: 20px;
    }
    .red{
        font-size: 10px;
    }
  }