.grid-card {
  position: relative;
  margin: 20px;
  width: 200px;
  height: 125px;
}
.grid-card::after {
  content: "";
  background: linear-gradient(to right, #3f0041, #9f3f2f);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotateY(0) perspective(100px);
  transition: .4s;
  backface-visibility: hidden;
}
.grid-img {
  height: 100px;
  object-fit: cover;
  overflow: hidden;
  object-position: center;
  transform: rotateY(180deg) perspective(100px);
  transition: .4s;
  backface-visibility: hidden;
}
.grid-card-show::after {
  transform: rotateY(180deg) perspective(100px);
}
.grid-card-show .grid-img {
  transform: rotateY(0) perspective(100px);
}
.grid-card-finished {
  opacity: .5;
}
