@import url('https://fonts.googleapis.com/css?family=Dancing+Script');
@import url('https://fonts.googleapis.com/css?family=Preahvihear');
.questionMark{
  font-size: 26px;
  cursor: pointer;
}

.page{
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.title{
  display: flex;
  justify-content: center;
  font-size: 30px;
  margin-top: 25px;
  font-family: 'Preahvihear' !important;
}

.subTitle{
  width: 75%;
  margin: 25px auto;
}

.homeButtonContainer{
  width: 50%;
  margin: 15px 24%;
}

/* CSS */
.homeButton {
  padding: 0px 25px;
  cursor: pointer;
  height: 50px; 
  background: linear-gradient(45deg, transparent 5%, #14000b 5%);
  border: 0;
  color: #fff;
  letter-spacing: 2px;
  box-shadow: 6px 0px 0px #00E6F6;
  outline: transparent;
  position: relative;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

